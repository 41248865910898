import { useMemo, useState } from 'react';
import { AiOutlineClose } from '@react-icons/all-files/ai/AiOutlineClose';
import _ from 'lodash';
import classNames from 'classnames';
import { Modal } from 'react-bootstrap';
import { VendorCard } from 'Components/molecules';
import { SearchBar } from 'Components/molecules';
import 'Theme/scroll.scss';
import { EmptySearchResults } from 'Components/molecules';
import IntegrationsFilter, {
    IntegrationFilterDataProps,
} from 'Components/molecules/IntegrationsFilter/IntegrationsFilter';
import { IntegrationSidebarType } from 'Components/organisms/AddIntegrationModal/AddIntegrationModal';
import './styles.scss';

type SectionType = 'text' | 'dropdown';
type FieldType = 'text' | 'dropdown' | 'checkbox' | 'radio' | 'switch';

export type Section = {
    _form: {
        field: string;
        required?: boolean;
    };
    type: SectionType;
    label: string;
    protected: boolean;
};

export type ConnectorSettingsFormSchema = {
    buttonText?: string;
    strategy?: 'credentials' | 'oauth';
    installErrorMessage?: string;
    installSuccessMessage?: string;
    sections: Section[];
    title: string;
    onSubmit: () => void;
    onValueChange: (section: Section, value: string) => void;
};

export type DynamicFieldOption = {
    key: string;
    value: string;
};

export type DynamicFieldT = {
    defaultValue?: string;
    encrypted?: boolean;
    formProps?: any;
    label?: string;
    name: string;
    options: string[] | DynamicFieldOption[];
    required?: boolean;
    type: FieldType;
    variant?: 'list'; // This is for comma separated text values
};

export type DynamicTab = {
    buttonText?: string;
    buttonType?: 'submit' | 'button';
    contentfulKey?: string;
    formId?: string;
    formTitle?: string;
    formTitleVariant?: string;
    fields?: DynamicFieldT[];
    name: string;
    strategy?: 'credentials' | 'oauth';
    type: 'text' | 'richText' | 'form' | 'buttonOnly' | 'slackBot' | 'custom';
    variant?: string;
};

export type IntegrationDataProps = {
    configurations: string[];
    id: string;
    imageUrl: string;
    installationFormSchema?: any;
    installationInstructions: string;
    integrationCopy: string;
    isIntegrated: boolean;
    overview?: string;
    providerKey?: string;
    settingsFormSchema?: ConnectorSettingsFormSchema;
    sideBar?: IntegrationSidebarType[];
    dynamicTabs?: { tabs: DynamicTab[]; strategy: 'credentials' | 'oauth' };
    tileDescription: string;
    vendor: string;
    cid?: string;
    domains?: { fields: { name: string; domain: string } }[];
    actionType?: string;
};
export interface IntegrationGridProps {
    data: IntegrationDataProps[];
    variant?: 'all' | 'selected';
    handleCardClick: (_id: string) => void;
}
export interface IntegrationsProps {
    title: string;
    integrationsData: IntegrationDataProps[];
    variant?: 'all' | 'selected';
    show: boolean;
    handleClose: () => void;
    handleCardClick: (_id: string) => void;
    searchQuery: string;
    onSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}
const IntegrationsGrid = ({ data, variant, handleCardClick }: IntegrationGridProps) => {
    const gridColClassName = classNames({
        'col-4': variant === 'all',
        'col-3': variant === 'selected',
    });
    const maxRowElements = variant === 'all' ? 3 : 4;

    const rows = [];

    for (let i = 0; i < data.length; i += maxRowElements) {
        const rowItems = data.slice(i, i + maxRowElements).map((ele) => (
            <div className={gridColClassName} key={ele.id}>
                <VendorCard
                    imageUrl={ele.imageUrl}
                    title={`${ele.vendor}`}
                    subText={ele.tileDescription}
                    isIntegrated={ele.isIntegrated}
                    handleCard={() => handleCardClick(ele.id)}
                    id={ele.id}
                />
            </div>
        ));

        rows.push(
            <div className="row me-0" key={i}>
                {rowItems}
            </div>,
        );
    }

    return <div className="integrations-grid d-grid gap-3 w-100 overflow-auto custom-scrollbar">{rows}</div>;
};

const getFiltersData = (data: any): IntegrationFilterDataProps[] => {
    const domainCounts: { [domain: string]: any } = {};
    data.forEach((datum: any) => {
        datum.domains?.forEach((domain: any) => {
            if (domainCounts[domain?.fields?.name]) {
                domainCounts[domain?.fields?.name] = domainCounts[domain?.fields?.name] + 1;
            } else {
                domainCounts[domain?.fields?.name] = 1;
            }
        });
    });

    const filters: IntegrationFilterDataProps[] = [{ text: 'All', count: data.length }];
    Object.keys(domainCounts).map((key) => {
        filters.push({
            text: key,
            count: domainCounts[key],
        });
    });

    return filters;
};

const Integrations = ({
    title,
    integrationsData,
    variant = 'selected',
    show,
    handleClose,
    handleCardClick,
    searchQuery,
    onSearchQueryChange,
}: IntegrationsProps) => {
    const [filter, setFilter] = useState('All');

    const filteredData = useMemo(() => {
        const tempData =
            filter === 'All'
                ? [...integrationsData]
                : // eslint-disable-next-line max-nested-callbacks
                  integrationsData.filter((ele) => ele?.domains?.find((domain) => domain?.fields?.name === filter));
        if (!searchQuery) {
            return tempData;
        }

        /*
         * 2023-09-14
         * Fixed sast "Incorrect regular expression" issue
         * -----------------------------------------------
         * The `RegExp` constructor was called with a non-literal variable. If an adversarywere able to
         * supply a malicious regex, they could cause a Regular Expression Denial of Service (ReDoS)
         * against the application.
         */
        const searchString = searchQuery.trim().toLocaleLowerCase();
        return tempData.filter((ele) => ele.vendor.toLocaleLowerCase().includes(searchString));
    }, [searchQuery, filter, integrationsData]);

    const filters = getFiltersData(integrationsData);
    const filteredDataClassName = classNames('d-flex justify-content-center gap-3 h-100 w-100 ', {
        'align-items-center': filteredData.length === 0,
    });
    return (
        <>
            <Modal show={show} className="integrations w-100" centered onHide={handleClose}>
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                    <AiOutlineClose onClick={handleClose} className="close-button" />
                </Modal.Header>
                <Modal.Body className="p-3 d-flex flex-column gap-3 m-0 overflow-hidden">
                    <SearchBar onChange={onSearchQueryChange} searchClassName="search-container__input-cont" />
                    <div className="d-flex gap-3 overflow-hidden custom-scrollbar h-100">
                        {variant === 'all' && (
                            <IntegrationsFilter
                                activeFilter={filter}
                                integrationsFilterData={filters}
                                onClickCategory={setFilter}
                            />
                        )}
                        <div className={filteredDataClassName}>
                            {filteredData.length === 0 ? (
                                <EmptySearchResults />
                            ) : (
                                <IntegrationsGrid
                                    data={filteredData}
                                    variant={variant}
                                    handleCardClick={handleCardClick}
                                />
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default Integrations;
