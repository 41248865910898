import { Button } from 'react-bootstrap';
import { Table } from 'Components/organisms';
import { useContext, useMemo, useState } from 'react';
import {
    AmpSettingDto,
    FilterCriteria,
    FindingSpecDto,
    FindingSpecKind,
    JAMF_SMART_GROUPS_SETTINGS,
    JamfSmartGroupDto,
    JamfSmartGroupsSetting,
    KIND,
} from '@ampsec/platform-client';
import { SMART_GROUPS_CONFIG_COLUMNS, SmartGroupStatus } from './SmartGroupsConfigColumns';
import FindingsModal from '../FindingsConfigTable/FindingsModal';
import {
    addFindingSpecs,
    getFindingSpecs,
    getJamfSmartGroupsSetting,
    updateFindings,
    updateSettings,
} from 'Apis/library';
import { DEFAULT_ERROR_MSG, INTEGRATIONHEADER, NEVER, REFRESH } from 'Core-utils/constants/constants';
import { ToastsContext } from 'Rdx/contexts/ToastsContext';
import { useApiObjectData } from 'Hooks/useApiObjectData';
import './styles.scss';
import { Typography } from 'Components/atoms';
import moment from 'moment';
import { useI18n } from 'Hooks/useI18n';

interface SmartGroupConfigTableProps {
    cid: string;
    filter?: FilterCriteria;
}

interface subHeaderContentProps {
    buttonLabel: string;
    disabled: boolean;
    onClickHandler?: React.MouseEventHandler<HTMLButtonElement>;
}
const SubHeaderContent = ({ buttonLabel, disabled, onClickHandler }: subHeaderContentProps) => {
    return (
        <Button className="bg-primary-500 body1 ps-4 pe-4 pt-2 pb-2" onClick={onClickHandler} disabled={disabled}>
            {buttonLabel}
        </Button>
    );
};
const ROW_PER_PAGE_OPTIONS = [10, 20, 30, 40];
const SmartGroupsConfigTable = ({ cid }: SmartGroupConfigTableProps) => {
    const [show, setShow] = useState<boolean>(false);
    const [_refreshData, setRefreshData] = useState<boolean>(false);
    const [findingSpecData, setFindingSpecData] = useState<FindingSpecDto>({} as FindingSpecDto);
    const [limit, setLimit] = useState(ROW_PER_PAGE_OPTIONS[0]);
    const [page, setPage] = useState(1);
    const [isFindingSpecLoading, setIsFindingSpecLoading] = useState<boolean>(true);
    const { addToast } = useContext(ToastsContext);
    const { data: jamfConnectorSettings, isLoading, refresh } = useApiObjectData<
        { [key: string]: AmpSettingDto },
        { [key: string]: AmpSettingDto }
    >(
        'JAMF_CONNECTOR_SETTINGS',
        () => getJamfSmartGroupsSetting(cid),
        (a) => a,
    );

    const SmartGroupData: JamfSmartGroupsSetting = (jamfConnectorSettings?.[JAMF_SMART_GROUPS_SETTINGS]
        ?.value as unknown) as JamfSmartGroupsSetting;
    const TABLE_BODY_HEIGHT = '25rem';
    const { t } = useI18n();

    const handleCloseModal = () => {
        setShow(false);
        setFindingSpecData({} as FindingSpecDto);
    };
    const handleSave = async (finding: FindingSpecDto) => {
        if (finding.id) {
            await updateFindings({ ...finding, kind: FindingSpecKind.CUSTOM })
                .then(() => setRefreshData((refreshData) => !refreshData))
                .catch(() =>
                    addToast({
                        severity: 'error',
                        body: DEFAULT_ERROR_MSG,
                    }),
                );
        } else {
            await addFindingSpecs({ ...finding, kind: FindingSpecKind.CUSTOM }).then(() =>
                setRefreshData((refreshData) => !refreshData),
            );
        }
    };
    const handleEditClick = async (row: JamfSmartGroupDto) => {
        if (row.status === SmartGroupStatus.ENABLED) {
            setShow(true);
            if (!row.groupId) {
                return;
            }
            setIsFindingSpecLoading(true);

            getFindingSpecs(KIND.FINDING_SPECS, { name: `JAMF_SMART_GROUP_${row.groupId}` })
                .then((res) => {
                    setFindingSpecData((res?.[0] ?? {}) as FindingSpecDto);
                })
                .finally(() => {
                    setIsFindingSpecLoading(false);
                });
        }
    };

    const changePageHandler = (currentPage: number) => {
        setPage(currentPage);
    };

    const changeRowsPerPageHandler = (rowsOption: number) => {
        setLimit(rowsOption);
    };
    const groupsData = useMemo(() => {
        const groups = SmartGroupData?.groups ?? [];
        if (groups.length === 0) {
            return [];
        }

        const startIndex = (page - 1) * limit;
        const endIndex = Math.min(startIndex + limit, groups.length);
        return groups.slice(startIndex, endIndex);
    }, [SmartGroupData?.groups, limit, page]);

    const handleRefresh = () => {
        updateSetting(REFRESH);
    };

    const handleUpdateStatus = (row: JamfSmartGroupDto, status: SmartGroupStatus) => {
        updateSetting({ ...row, status: status });
    };

    const updateSetting = (value: unknown) => {
        const existingSetting = jamfConnectorSettings?.[JAMF_SMART_GROUPS_SETTINGS] as AmpSettingDto;
        if (existingSetting) {
            updateSettings(existingSetting.id, {
                id: existingSetting.id,
                key: JAMF_SMART_GROUPS_SETTINGS,
                value: value,
            } as AmpSettingDto).then(() => refresh());
        }
    };

    if (!jamfConnectorSettings?.[JAMF_SMART_GROUPS_SETTINGS]) {
        return (
            <Typography variant="subtitle3" className="text-text-high-emphasis">
                {t('NO_JAMF_SMART_GROUP_SETTINGS')}
            </Typography>
        );
    }
    const buttonLabel =
        SmartGroupData.syncStatus === 'SYNCING' ? 'Syncing JAMF Smart Groups' : 'Refresh JAMF Smart Groups';
    return (
        <div className="smart-group">
            <div className="d-flex flex-row align-items-center gap-1">
                <Typography variant="subtitle3" color="text-medium-emphasis">
                    {INTEGRATIONHEADER[1]}
                </Typography>
                <Typography variant="body5" color="text-medium-emphasis" className="gap-1">
                    {SmartGroupData?.lastSynced ? moment(SmartGroupData?.lastSynced).fromNow() : NEVER}
                </Typography>
            </div>
            <Table
                cacheKey={'findingSpecs'}
                data={groupsData ?? []}
                dataIsLoading={isLoading}
                columns={SMART_GROUPS_CONFIG_COLUMNS(handleEditClick, handleUpdateStatus)}
                rowsPerPageOptions={ROW_PER_PAGE_OPTIONS}
                transformer={(a) => a}
                subHeaderVariant="with-button"
                subHeaderContent={
                    <SubHeaderContent
                        buttonLabel={buttonLabel}
                        onClickHandler={handleRefresh}
                        disabled={SmartGroupData.syncStatus === 'SYNCING'}
                    />
                }
                placeHolder="Search..."
                tableBodyHeight={TABLE_BODY_HEIGHT}
                searchKey="displayValue"
                onRowClicked={handleEditClick}
                onChangePage={changePageHandler}
                onRowChange={changeRowsPerPageHandler}
                currentPage={page}
                currentLimit={limit}
                paginationTotalRows={SmartGroupData?.groups?.length ?? 0}
            />
            <FindingsModal
                show={show}
                onClose={handleCloseModal}
                onSave={handleSave}
                defaultFindingType={findingSpecData}
                isDataLoading={isFindingSpecLoading}
            />
        </div>
    );
};

export default SmartGroupsConfigTable;
