import { ActionItemType, CanvasType, EngagementPillsCategory } from 'Core-utils/types';
import { CustomTooltip, Typography } from 'Components/atoms';
import { DynamicDropdown } from 'Components/molecules';
import { DynamicDropdownProps } from 'Components/molecules/DynamicDropdown/DynamicDropdown';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import { DropdownComponent } from 'Components/molecules';
import { generateDropdownValues } from 'Core-utils/helpers/helpers';
import { IoMdInformationCircleOutline } from '@react-icons/all-files/io/IoMdInformationCircleOutline';
import { HiInformationCircle } from '@react-icons/all-files/hi2/HiInformationCircle';
import {
    CONFIGURE_MORE_ACTIONS,
    DO_NOTHING_ID,
    DO_NOTHING_TEXT,
    DONT_CHANGE,
    generateAllItems,
    generateAllStatus,
} from 'Core-utils/constants/constants';
import { ACTIONS_WORKFLOWS_ROUTE } from 'Core-utils/routes';
import { getEngagementChannelOptions } from 'Components/organisms/EngagementChannelExpandable/EngagementChannelExpandable';
import './styles.scss';
import { EngagementRedirectionKind } from '@ampsec/platform-client';
import _ from 'lodash';
import { useI18n } from 'Hooks/useI18n';
import { MultiValue } from 'react-select';
import { useEffect, useState } from 'react';
import ChipMultiSelect, { OptionType } from '../ChipMultiSelect/ChipMultiSelect';
import CustomLogo from '../../../../public/assets/icons/AmpSec.svg';

export const MESSAGE_TONE_ID = '1';
export const SCHEDULE_RESOLUTION_ID = '2';
export const RESOLUTION_DEADLINE_ID = '3';
export const REMINDER_ID = '4';
export const HELP_ID = '5';
export const RESOLUTION_EXPIRY_ACTION_ID = '6';
export const RESOLVE_NOW_ID = '7';
export const TRIAGE_ID = '8';
export const CUSTOM_MESSAGE_ID = '9';
export const ADDITIONAL_CONTEXT_ID = '10';
export const INCLUDE_MANAGER_ID = '11';
export const INSTRUCTION_ID = '12';
export const ESCALATION_ID = '13';
export const MARK_COMPLETE_ID = '14';
export const REWARDS_ID = '15';
export const DELAY_ENGAGEMENT_ID = '16';
export const TRAINING_ID = '17';
export const REDIRECT_ENGAGEMENT_ID = '18';
export const ACTION_BUTTON_ID = '19';
export const LAUNCH_ACTIONS_ID = '20';
export const MANAGER_ESCALATION_ID = '21';

type SingleDropdownSelection<T> = Pick<DynamicDropdownProps<T>, 'options' | 'optionWithLinks'> & {
    selectedOptions?: string;
    onOptionSelected: (option: string, fieldName: string, id: string, selectedId?: string) => void;
    fieldName: string;
    id: string;
    isStartCase?: boolean;
};

type MultiDropdownSelection<T> = Pick<DynamicDropdownProps<T>, 'options'> & {
    selectedOptions?: string[];
    onOptionSelected: (option: string[], fieldName: string, id: string) => void;
    fieldName: string;
    id: string;
};

export type RewardsProps = {
    value?: string;
    id: string;
    duration?: string;
    time?: string;
    unit?: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
} & Pick<SingleDropdownSelection<string>, 'onOptionSelected'>;

export type MessageToneProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    nature?: string;
};

export type ResolutionDeadlineProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    deadlineTime?: string;
    deadlineUnit?: string;
};

export type EscalationProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    isManagerEscalation?: boolean;
    escalationTime?: string;
    escalationUnit?: string;
    escalationChannel?: string;
    selectedOptions: { id: string; icon: string; value: string }[];
    redirectionKind?: EngagementRedirectionKind;
    channelId?: string;
};

type TextAreaWrapperProps = {
    value?: string;
    onChange: (option: string, fieldName: string, id: string) => void;
    fieldName: string;
    id: string;
    onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
    variant?: 'custom' | 'context';
    placeHolder?: string;
    maxLength?: number;
    className?: string;
};

type ToggleWrapperProps = {
    id: string;
    checked?: boolean;
    onChange?: () => void;
};

type CustomMessageProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    template?: string;
    checked?: boolean;
    onChange?: () => void;
    error?: boolean;
};

type HelpProps = Pick<MultiDropdownSelection<string[]>, 'onOptionSelected'> & {
    id: string;
    options?: string[];
};

export type ReminderProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    reminderTime?: string;
    reminderUnit?: string;
};

type ResolutionExpiryActionProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    actionId?: string;
    actions: ActionItemType[];
    findingStatus?: string;
    error?: boolean;
};

type ResolveNowProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    actionId?: string;
    actions: ActionItemType[];
    findingStatus?: string;
    error?: boolean;
};

type ActionButtonProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> &
    Pick<MultiDropdownSelection<string[]>, 'onOptionSelected'> & {
        id: string;
        actionIds?: string[];
        actionLabel?: string;
        actions: ActionItemType[];
        findingStatus?: string;
        error?: boolean;
    };

type TriageProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    yesActionId?: string;
    noActionId?: string;
    actions: ActionItemType[];
    yesActionStatus?: string;
    noActionStatus?: string;
    yesResponseMessage?: string;
    noResponseMessage?: string;
    triageMessage?: string;
    error?: boolean;
};

type AdditionalContextProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    data?: string;
    error?: boolean;
};

type ScheduleResolutionProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    actions: ActionItemType[];
    actionId?: string;
    findingStatus?: string;
};

type LaunchActionProps = {
    actions: ActionItemType[];
    id: string;
    actionId?: string;
    findingStatus?: string;
} & Pick<SingleDropdownSelection<string>, 'onOptionSelected'>;

type LaunchActionsV2Props = {
    actions: ActionItemType[];
    id: string;
    actionIds?: string[];
    findingStatus?: string;
} & Pick<MultiDropdownSelection<string>, 'onOptionSelected'>;

export type DelayEngagementProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    delayEngagementTime?: string;
    delayEngagementUnit?: string;
};

export type TrainingProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    subject?: string;
    context?: string;
    error?: boolean;
};

export type RedirectEngagementProps = Pick<SingleDropdownSelection<string>, 'onOptionSelected'> & {
    id: string;
    redirectionKind?: EngagementRedirectionKind;
    channelId?: string;
    includeEmployee?: string;
    error?: boolean;
};

const MultiSelectDropdownWrapper = ({
    options,
    selectedOptions,
    onOptionSelected,
    fieldName,
    id,
}: MultiDropdownSelection<string>) => {
    const handleCheckbox = (option: string[] | undefined) => {
        if (option !== undefined) {
            onOptionSelected(option, fieldName, id);
        }
    };
    const normalizedSelectedOptions = selectedOptions ?? [];

    return (
        <DynamicDropdown
            variant="multiSelect"
            selectedOptions={normalizedSelectedOptions}
            onOptionSelected={handleCheckbox}
            options={options}
            selectedOptionsClassName="custom"
        />
    );
};

const SingleSelectDropdownWrapper = ({
    options,
    selectedOptions,
    onOptionSelected,
    fieldName,
    id,
    isStartCase,
}: SingleDropdownSelection<string>) => {
    const handleCheckbox = (option: string) => {
        onOptionSelected(option, fieldName, id);
    };
    return (
        <DynamicDropdown
            variant="default"
            selectedOptions={selectedOptions ?? ''}
            onOptionSelected={handleCheckbox}
            options={options}
            isStartCase={isStartCase}
        />
    );
};

const ActionsDropdownWrapper = ({
    options,
    selectedOptions,
    onOptionSelected,
    fieldName,
    id,
    displayActionsRoute = true,
    maxWidth,
}: any) => {
    const handleCheckbox = (selectedOption: string | null, event: any) => {
        onOptionSelected(selectedOption, fieldName, id, event.currentTarget.id);
    };
    const statusFieldNames = ['findingStatus', 'yesActionStatus', 'noActionStatus'];
    const updatedOptions = displayActionsRoute
        ? [
              ...options,
              {
                  id: CONFIGURE_MORE_ACTIONS,
                  value: CONFIGURE_MORE_ACTIONS,
                  icon: <HiInformationCircle className="text-primary-500 side-panel__icon" />,
                  link: ACTIONS_WORKFLOWS_ROUTE,
              },
          ]
        : options;
    const option = options.find((item: any) => item?.id === selectedOptions);
    let value = statusFieldNames.includes(fieldName) ? selectedOptions : option?.value;
    if (option?.displayValue) {
        value = option?.displayValue;
    }
    return (
        <DropdownComponent
            value={value}
            placeholder="Select"
            dropDownItems={updatedOptions}
            onChange={handleCheckbox}
            selectedItemIcon={options.find((item: any) => item?.id === selectedOptions)?.icon}
            maxWidth={maxWidth ?? '21.25rem'}
        />
    );
};

const ToggleWrapper = ({ id, onChange, checked }: ToggleWrapperProps) => {
    return <Form.Check type="switch" checked={checked} onChange={onChange} id={id.toString()} />;
};

const TextAreaWrapper = ({
    value,
    onChange,
    id,
    fieldName,
    variant,
    placeHolder,
    maxLength,
    className,
}: TextAreaWrapperProps) => {
    const handleChange = (event: any) => {
        onChange(event.target.value, fieldName, id);
    };
    return (
        <Form.Control
            as="textarea"
            value={value}
            placeholder={placeHolder}
            onChange={handleChange}
            className={classNames(
                `shadow-none subtitle7 w-100 bg-transparent text-text-high-emphasis overflow-auto custom-scrollbar side-panel__text-area p-1 ${className}`,
                { 'border-0': variant === 'custom', 'side-panel__context-area': variant === 'context' },
            )}
            maxLength={maxLength}
        />
    );
};

const DbValueTextAreaWrapper = ({ value, onChange, id, fieldName, onKeyDown }: TextAreaWrapperProps) => {
    const handleChange = (event: any) => {
        onChange(event.target.value, fieldName, id);
    };
    return (
        <Form.Control
            id={id.toString()}
            type="text"
            value={value}
            onChange={handleChange}
            onKeyDown={onKeyDown}
            className="shadow-none bg-transparent side-panel__db-text-area text-text-high-emphasis"
        />
    );
};

const Rewards = ({ value, id, duration, unit, time, onOptionSelected, onKeyDown }: RewardsProps) => (
    <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
        On completing the action&nbsp;&nbsp;
        <SingleSelectDropdownWrapper
            options={['within', 'after']}
            selectedOptions={duration}
            onOptionSelected={onOptionSelected}
            fieldName="duration"
            id={id}
        />
        &nbsp;
        <SingleSelectDropdownWrapper
            options={Array.from({ length: 31 }, (_, index) => (index + 1).toString())}
            selectedOptions={time}
            onOptionSelected={onOptionSelected}
            fieldName="time"
            id={id}
        />
        &nbsp;
        <SingleSelectDropdownWrapper
            options={['hours', 'days', 'weeks']}
            selectedOptions={unit}
            onOptionSelected={onOptionSelected}
            fieldName="unit"
            id={id}
        />
        &nbsp; users will be rewarded&nbsp;&nbsp;
        <DbValueTextAreaWrapper
            value={value}
            onChange={onOptionSelected}
            id={id}
            fieldName="value"
            onKeyDown={onKeyDown}
        />
        &nbsp; dBs
    </Typography>
);

const LaunchAction = ({ id, actions, actionId, onOptionSelected, findingStatus }: LaunchActionProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Launch
            </Typography>
            <ActionsDropdownWrapper
                options={generateAllItems(actions)}
                selectedOptions={actionId}
                fieldName="actionId"
                onOptionSelected={onOptionSelected}
                id={id}
            />
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                immediately
            </Typography>
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                and change finding status to
            </Typography>
            <div className="side-panel__dropdown">
                <ActionsDropdownWrapper
                    options={generateAllStatus()}
                    fieldName="findingStatus"
                    id={id}
                    selectedOptions={findingStatus ?? DONT_CHANGE}
                    onOptionSelected={onOptionSelected}
                    displayActionsRoute={false}
                />
            </div>
        </div>
    );
};

const LaunchActions = ({ id, actions, actionIds, onOptionSelected, findingStatus }: LaunchActionsV2Props) => {
    const defaultSelectedOptions: OptionType[] = generateAllItems(actions)
        .filter((item) => actionIds?.includes(item.id))
        .map((item) => {
            return {
                value: item.id ?? DO_NOTHING_ID,
                label: item.value ?? DO_NOTHING_TEXT,
                icon: item.icon ?? CustomLogo,
            };
        });
    const dropdownItems: OptionType[] = generateAllItems(actions)
        .filter((item) => item !== null)
        .map((item) => {
            return {
                value: item.id ?? DO_NOTHING_ID,
                label: item.value ?? DO_NOTHING_TEXT,
                icon: item.icon ?? CustomLogo,
            };
        });
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
    useEffect(() => {
        if (defaultSelectedOptions.length > 0) {
            setSelectedOptions(defaultSelectedOptions);
        }
    }, [actions]);
    const handleOptionSelected = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedOptions(selectedOptions as OptionType[]);
        onOptionSelected(selectedValues, 'actionIds', id);
    };
    return (
        <div className="d-flex flex-column gap-2 w-100 align-items-center">
            <div className="d-flex w-100 align-items-center">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    Launch
                </Typography>
                <div className="side-panel__multi-select-container">
                    <ChipMultiSelect
                        id={id}
                        selected={selectedOptions}
                        onChange={handleOptionSelected}
                        placeholder={'Select'}
                        options={dropdownItems}
                    />
                </div>
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    immediately and change finding status to&nbsp;&nbsp;
                </Typography>
                <div className="side-panel__dropdown">
                    <ActionsDropdownWrapper
                        options={generateAllStatus()}
                        fieldName="findingStatus"
                        id={id}
                        selectedOptions={findingStatus ?? DONT_CHANGE}
                        onOptionSelected={onOptionSelected}
                        displayActionsRoute={false}
                    />
                </div>
            </div>
        </div>
    );
};

const DelayEngagement = ({ id, delayEngagementTime, delayEngagementUnit, onOptionSelected }: DelayEngagementProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Delay engagement for
            </Typography>
            <SingleSelectDropdownWrapper
                options={generateDropdownValues()}
                fieldName="delayEngagementTime"
                onOptionSelected={onOptionSelected}
                selectedOptions={delayEngagementTime}
                id={id}
            />
            <SingleSelectDropdownWrapper
                options={['hours', 'days', 'weeks']}
                fieldName="delayEngagementUnit"
                onOptionSelected={onOptionSelected}
                selectedOptions={delayEngagementUnit}
                id={id}
            />
        </div>
    );
};

const Training = ({ id, subject, context, onOptionSelected, error }: TrainingProps) => {
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex gap-2 w-100">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    Create a micro training for the user about
                </Typography>
                <div className="w-100 h-50">
                    <TextAreaWrapper
                        onChange={onOptionSelected}
                        fieldName="subject"
                        id={id}
                        value={subject}
                        variant="context"
                        placeHolder="Subject"
                        maxLength={1000}
                        className="side-panel__text-area-cont"
                    />
                    {error && (subject?.length ?? 0) > 1000 && (
                        <Typography variant="overline2" color="vizualization-red-200">
                            Input cannot exceed 1000 characters
                        </Typography>
                    )}
                </div>
            </div>
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                to be included with the initial message. Optionally include the following context when generating the
                training content
            </Typography>
            <TextAreaWrapper
                onChange={onOptionSelected}
                fieldName="context"
                id={id}
                value={context}
                variant="context"
                placeHolder="Context"
                maxLength={8000}
            />
            {error && (context?.length ?? 0) > 8000 && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Input cannot exceed 8000 characters
                </Typography>
            )}
        </div>
    );
};

const getPlaceHolderByRedirectionKind = (kind: EngagementRedirectionKind | undefined | 'EMAIL_CHANNEL'): string => {
    switch (kind) {
        case EngagementRedirectionKind.TEAMS_CHANNEL:
            return 'Channel Link';
        case EngagementRedirectionKind.EMAIL_CHANNEL:
            return 'Email Address';
        case EngagementRedirectionKind.SLACK_CHANNEL:
        default:
            return 'Channel Id';
    }
};

const RedirectEngagement = ({
    id,
    redirectionKind,
    channelId,
    includeEmployee,
    onOptionSelected,
    error,
}: RedirectEngagementProps) => {
    const { t } = useI18n();
    const className = classNames('d-flex gap-2', {
        'flex-column w-100': redirectionKind === EngagementRedirectionKind.TEAMS_CHANNEL,
    });
    return (
        <div className={className}>
            <div className="d-flex gap-2">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    Engage
                </Typography>
                <div className="d-flex w-20">
                    <SingleSelectDropdownWrapper
                        options={Object.values(EngagementRedirectionKind)}
                        fieldName="redirectionKind"
                        onOptionSelected={onOptionSelected}
                        id={id}
                        selectedOptions={redirectionKind}
                        isStartCase={true}
                    />
                </div>
                <TextAreaWrapper
                    onChange={onOptionSelected}
                    fieldName="channelId"
                    id={id}
                    value={channelId}
                    variant="context"
                    placeHolder={getPlaceHolderByRedirectionKind(redirectionKind)}
                    className="side-panel__channel-text-area"
                    maxLength={1500}
                />
            </div>
            <div className="d-flex gap-2">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    and
                </Typography>
                <div className="d-flex w-20">
                    <SingleSelectDropdownWrapper
                        options={['omit']}
                        fieldName="includeEmployee"
                        onOptionSelected={onOptionSelected}
                        id={id}
                        selectedOptions={includeEmployee}
                    />
                </div>
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    employee in engagements
                </Typography>
                <CustomTooltip label={t('REDIRECT_ENGAGEMENT_HELP')}>
                    <IoMdInformationCircleOutline className="text-text-low-emphasis" />
                </CustomTooltip>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Invalid input
                </Typography>
            )}
        </div>
    );
};

const MessageTone = ({ onOptionSelected, id, nature }: MessageToneProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Send all messages with a
            </Typography>
            <SingleSelectDropdownWrapper
                options={['Humorous', 'Friendly', 'Serious']}
                fieldName="nature"
                onOptionSelected={onOptionSelected}
                id={id}
                selectedOptions={nature}
            />
            <Typography variant="subtitle7" style={{ marginBottom: '0%', display: 'flex', alignItems: 'center' }}>
                tone.
            </Typography>
        </div>
    );
};

const ScheduleResolution = ({ onOptionSelected, id, actionId, actions, findingStatus }: ScheduleResolutionProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Require users to schedule a resolution time and take
            </Typography>
            <div className="side-panel__action">
                <ActionsDropdownWrapper
                    options={generateAllItems(actions)}
                    fieldName="actionId"
                    id={id}
                    selectedOptions={actionId}
                    onOptionSelected={onOptionSelected}
                />
            </div>
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                and change finding status to
            </Typography>
            <div className="side-panel__dropdown">
                <ActionsDropdownWrapper
                    options={generateAllStatus()}
                    fieldName="findingStatus"
                    id={id}
                    selectedOptions={findingStatus ?? DONT_CHANGE}
                    onOptionSelected={onOptionSelected}
                    displayActionsRoute={false}
                />
            </div>
        </div>
    );
};

const INCLUDE_MANAGER = () => <>Copies direct manager in all engagements when available.</>;

const INSTRUCTIONS = () => <>Provide detailed instructions button.</>;

const MARK_COMPLETE = () => <>Provide mark complete button.</>;

const ResolutionDeadline = ({ id, deadlineTime, deadlineUnit, onOptionSelected }: ResolutionDeadlineProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                This finding should be resolved within
            </Typography>
            <SingleSelectDropdownWrapper
                options={generateDropdownValues()}
                fieldName="deadlineTime"
                onOptionSelected={onOptionSelected}
                selectedOptions={deadlineTime}
                id={id}
            />
            <SingleSelectDropdownWrapper
                options={['hours', 'days', 'weeks']}
                fieldName="deadlineUnit"
                onOptionSelected={onOptionSelected}
                selectedOptions={deadlineUnit}
                id={id}
            />
        </div>
    );
};

const Reminder = ({ onOptionSelected, id, reminderTime, reminderUnit }: ReminderProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Remind users every
            </Typography>
            <SingleSelectDropdownWrapper
                options={generateDropdownValues()}
                selectedOptions={reminderTime}
                id={id}
                fieldName="reminderTime"
                onOptionSelected={onOptionSelected}
            />
            <SingleSelectDropdownWrapper
                options={['hours', 'days', 'weeks']}
                selectedOptions={reminderUnit}
                id={id}
                fieldName="reminderUnit"
                onOptionSelected={onOptionSelected}
            />
        </div>
    );
};

const Help = ({ onOptionSelected, id, options }: HelpProps) => {
    return (
        <div className="d-flex gap-2">
            <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                Provide button for getting help via
            </Typography>
            <div className="side-panel__action">
                <MultiSelectDropdownWrapper
                    options={['Option1', 'Option2', 'Option3']}
                    id={id}
                    onOptionSelected={onOptionSelected}
                    fieldName="multiSelect"
                    selectedOptions={options}
                />
            </div>
        </div>
    );
};

const ResolutionExpiryAction = ({
    id,
    onOptionSelected,
    actionId,
    actions,
    findingStatus,
    error,
}: ResolutionExpiryActionProps) => {
    return (
        <div className="d-flex flex-column gap-1">
            <div className="d-flex gap-2">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    When user fails to resolve by the deadline, take
                </Typography>
                <div className="side-panel__action">
                    <ActionsDropdownWrapper
                        options={generateAllItems(actions)}
                        fieldName="actionId"
                        id={id}
                        selectedOptions={actionId}
                        onOptionSelected={onOptionSelected}
                    />
                </div>
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    and change finding status to
                </Typography>
                <div className="side-panel__dropdown">
                    <ActionsDropdownWrapper
                        options={generateAllStatus()}
                        fieldName="findingStatus"
                        id={id}
                        selectedOptions={findingStatus ?? DONT_CHANGE}
                        onOptionSelected={onOptionSelected}
                        displayActionsRoute={false}
                    />
                </div>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Please select a action.
                </Typography>
            )}
        </div>
    );
};

const ResolveNow = ({ id, actionId, onOptionSelected, actions, findingStatus, error }: ResolveNowProps) => {
    return (
        <div className="d-flex flex-column gap-1">
            <div className="d-flex gap-2">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        marginBottom: '0%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    Provide users with “Resolve Now” button to launch predefined &nbsp;&nbsp;
                    <div className="side-panel__action">
                        <ActionsDropdownWrapper
                            options={generateAllItems(actions)}
                            id={id}
                            fieldName="actionId"
                            selectedOptions={actionId}
                            onOptionSelected={onOptionSelected}
                        />
                    </div>
                    &nbsp;&nbsp;automation instantly.&nbsp; The finding status should be changed to&nbsp;
                    <span className="side-panel__dropdown">
                        <ActionsDropdownWrapper
                            options={generateAllStatus()}
                            id={id}
                            fieldName="findingStatus"
                            selectedOptions={findingStatus ?? DONT_CHANGE}
                            onOptionSelected={onOptionSelected}
                            displayActionsRoute={false}
                        />
                    </span>
                </Typography>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Please select a action.
                </Typography>
            )}
        </div>
    );
};

const ActionButton = ({
    id,
    actionIds,
    actionLabel,
    onOptionSelected,
    actions,
    findingStatus,
    error,
}: ActionButtonProps) => {
    const errorText = !actionLabel
        ? 'Please select an action.'
        : _.isEmpty(actionIds)
        ? 'Please select an action.'
        : 'Action label must be unique';
    const defaultSelectedOptions: OptionType[] = generateAllItems(actions)
        .filter((item) => actionIds?.includes(item.id))
        .map((item) => {
            return {
                value: item.id ?? DO_NOTHING_ID,
                label: item.value ?? DO_NOTHING_TEXT,
                icon: item.icon ?? CustomLogo,
            };
        });
    const dropdownItems: OptionType[] = generateAllItems(actions)
        .filter((item) => item !== null)
        .map((item) => {
            return {
                value: item.id ?? DO_NOTHING_ID,
                label: item.value ?? DO_NOTHING_TEXT,
                icon: item.icon ?? CustomLogo,
            };
        });
    const [selectedOptions, setSelectedOptions] = useState<OptionType[]>([]);
    useEffect(() => {
        if (defaultSelectedOptions.length > 0) {
            setSelectedOptions(defaultSelectedOptions);
        }
    }, [actions]);
    const handleOptionSelected = (selectedOptions: MultiValue<{ value: string; label: string }>) => {
        const selectedValues = selectedOptions.map((option) => option.value);
        setSelectedOptions(selectedOptions as OptionType[]);
        onOptionSelected(selectedValues, 'actionIds', id);
    };
    return (
        <div className="d-flex flex-column gap-2 w-100">
            <div className="d-flex gap-1 align-items-center">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        marginBottom: '0%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    Provide users with button with label
                </Typography>
                <span className="side-panel__text-area-action-label ps-1">
                    <TextAreaWrapper
                        onChange={onOptionSelected}
                        fieldName="actionLabel"
                        id={id}
                        value={actionLabel}
                        variant="context"
                        placeHolder="Action Name"
                        className="side-panel__channel-text-area"
                    />
                </span>
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        marginBottom: '0%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    to launch predefined
                </Typography>
                <div className="side-panel__multi-select-container">
                    <ChipMultiSelect
                        id={id}
                        selected={selectedOptions}
                        onChange={handleOptionSelected}
                        placeholder={'Select'}
                        options={dropdownItems}
                    />
                </div>
            </div>
            <div className="d-flex">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        marginBottom: '0%',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                    }}
                >
                    &nbsp;&nbsp;automation instantly.The finding status should be changed to&nbsp;
                </Typography>
                <span className="side-panel__dropdown">
                    <ActionsDropdownWrapper
                        options={generateAllStatus()}
                        id={id}
                        fieldName="findingStatus"
                        selectedOptions={findingStatus ?? DONT_CHANGE}
                        onOptionSelected={onOptionSelected}
                        displayActionsRoute={false}
                    />
                </span>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    {errorText}
                </Typography>
            )}
        </div>
    );
};

const Triage = ({
    id,
    onOptionSelected,
    yesActionId,
    noActionId,
    actions,
    yesActionStatus,
    noActionStatus,
    yesResponseMessage,
    noResponseMessage,
    triageMessage,
    error,
}: TriageProps) => {
    return (
        <div className="d-flex flex-column gap-2">
            <div className="d-flex flex-column">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '0%',
                        gap: '0.5rem',
                    }}
                >
                    Yes/No question for user to triage the finding &nbsp;&nbsp;
                    <div className="d-flex flex-column gap-3 w-100 side-panel__custom-message">
                        <TextAreaWrapper
                            onChange={onOptionSelected}
                            fieldName="triageMessage"
                            id={id}
                            value={triageMessage}
                            variant="custom"
                            placeHolder="Triage Question"
                        />
                    </div>
                </Typography>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Please Enter Message.
                </Typography>
            )}
            <div className="d-flex flex-column">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '0%',
                        gap: '0.5rem',
                    }}
                >
                    If user answers YES, take &nbsp;
                    <div className="side-panel__action">
                        <ActionsDropdownWrapper
                            options={generateAllItems(actions)}
                            id={id}
                            fieldName="yesActionId"
                            selectedOptions={yesActionId}
                            onOptionSelected={onOptionSelected}
                        />
                    </div>
                    &nbsp;&nbsp;and change finding status to &nbsp;&nbsp;
                    <div className="side-panel__action">
                        <ActionsDropdownWrapper
                            options={generateAllStatus()}
                            id={id}
                            fieldName="yesActionStatus"
                            selectedOptions={yesActionStatus ?? DONT_CHANGE}
                            onOptionSelected={onOptionSelected}
                            displayActionsRoute={false}
                        />
                    </div>
                    &nbsp;&nbsp;and respond to user with &nbsp;&nbsp;
                    <div className="d-flex flex-column gap-3 w-100 side-panel__custom-message">
                        <TextAreaWrapper
                            onChange={onOptionSelected}
                            fieldName="yesResponseMessage"
                            id={id}
                            value={yesResponseMessage}
                            variant="custom"
                            placeHolder="Response Message"
                        />
                    </div>
                </Typography>
            </div>
            <div className="d-flex flex-column">
                <Typography
                    variant="subtitle7"
                    style={{
                        whiteSpace: 'nowrap',
                        display: 'flex',
                        alignItems: 'center',
                        flexWrap: 'wrap',
                        marginBottom: '0%',
                        gap: '0.5rem',
                    }}
                >
                    If user answers NO, take &nbsp;
                    <div className="side-panel__action">
                        <ActionsDropdownWrapper
                            options={generateAllItems(actions)}
                            id={id}
                            fieldName="noActionId"
                            selectedOptions={noActionId}
                            onOptionSelected={onOptionSelected}
                        />
                    </div>
                    &nbsp;&nbsp;and change finding status to &nbsp;&nbsp;
                    <div className="side-panel__action">
                        <ActionsDropdownWrapper
                            options={generateAllStatus()}
                            id={id}
                            fieldName="noActionStatus"
                            selectedOptions={noActionStatus ?? DONT_CHANGE}
                            onOptionSelected={onOptionSelected}
                            displayActionsRoute={false}
                        />
                    </div>
                    &nbsp;&nbsp;and respond to user with &nbsp;&nbsp;
                    <div className="d-flex flex-column gap-3 w-100 side-panel__custom-message">
                        <TextAreaWrapper
                            onChange={onOptionSelected}
                            fieldName="noResponseMessage"
                            id={id}
                            value={noResponseMessage}
                            variant="custom"
                            placeHolder="Response Message"
                        />
                    </div>
                </Typography>
            </div>
        </div>
    );
};

const CustomMessage = ({ onOptionSelected, id, template, onChange, checked, error }: CustomMessageProps) => {
    return (
        <div className="d-flex flex-column w-100">
            <div className="d-flex flex-column gap-3 w-100 side-panel__custom-message">
                <TextAreaWrapper
                    onChange={onOptionSelected}
                    fieldName="template"
                    id={id}
                    value={template}
                    variant="custom"
                    placeHolder="Custom Message"
                />
                <div className="side-panel__hr" />
                <div className="d-flex gap-2 ps-3 pb-3">
                    <ToggleWrapper id={id} onChange={onChange} checked={checked} />
                    <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                        Only include custom message
                    </Typography>
                </div>
            </div>
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Please Enter Message.
                </Typography>
            )}
        </div>
    );
};

const AdditionalContext = ({ onOptionSelected, id, data, error }: AdditionalContextProps) => {
    return (
        <div className="d-flex flex-column gap-1 w-100">
            <TextAreaWrapper
                onChange={onOptionSelected}
                fieldName="data"
                id={id}
                value={data}
                variant="context"
                placeHolder="Additional Context"
            />
            {error && (
                <Typography variant="overline2" color="vizualization-red-200">
                    Please Enter Message.
                </Typography>
            )}
        </div>
    );
};

const Escalation = ({
    id,
    escalationTime,
    escalationUnit,
    escalationChannel,
    selectedOptions,
    onOptionSelected,
    isManagerEscalation = false,
    channelId,
    redirectionKind,
}: EscalationProps) => {
    const { dropdownItems } = getEngagementChannelOptions();
    const escalateToManagerDropdownOptions = dropdownItems
        .map((mappedItem) => mappedItem.value)
        .filter((value): value is string => value !== undefined);
    const dropdownEngagementOptions = dropdownItems
        .filter((item) => !selectedOptions.map((option) => option?.id)?.includes(item?.id || ''))
        ?.map((mappedItem) => mappedItem.value)
        .filter((value): value is string => value !== undefined);
    const defaultEscalationChannel = isManagerEscalation
        ? escalateToManagerDropdownOptions[0]
        : dropdownEngagementOptions[0];
    return (
        <div className="d-flex gap-2 flex-column">
            <div className="d-flex gap-2">
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    If finding is not resolved within
                </Typography>
                <SingleSelectDropdownWrapper
                    options={generateDropdownValues()}
                    fieldName="escalationTime"
                    onOptionSelected={onOptionSelected}
                    selectedOptions={escalationTime ?? '3'}
                    id={id}
                />
                <SingleSelectDropdownWrapper
                    options={['hours', 'days', 'weeks']}
                    fieldName="escalationUnit"
                    onOptionSelected={onOptionSelected}
                    selectedOptions={escalationUnit ?? 'days'}
                    id={id}
                />
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    {isManagerEscalation ? 'escalate to the user’s manager via' : 'then also engage in'}
                </Typography>
                <SingleSelectDropdownWrapper
                    options={isManagerEscalation ? escalateToManagerDropdownOptions : dropdownEngagementOptions ?? []}
                    fieldName="escalationChannel"
                    onOptionSelected={onOptionSelected}
                    selectedOptions={escalationChannel ?? defaultEscalationChannel}
                    id={id}
                />
                <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                    channel.
                </Typography>
            </div>
            {isManagerEscalation && (
                <div className="d-flex gap-2">
                    <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                        If the manager’s details are not found, escalate to
                    </Typography>
                    <div className="d-flex w-20">
                        <SingleSelectDropdownWrapper
                            options={Object.values(EngagementRedirectionKind)}
                            fieldName="redirectionKind"
                            onOptionSelected={onOptionSelected}
                            id={id}
                            selectedOptions={redirectionKind}
                            isStartCase={true}
                        />
                    </div>
                    <Typography variant="subtitle7" className="d-flex align-items-center mb-0 text-nowrap">
                        on
                    </Typography>
                    <TextAreaWrapper
                        onChange={onOptionSelected}
                        fieldName="channelId"
                        id={id}
                        value={channelId}
                        variant="context"
                        placeHolder={getPlaceHolderByRedirectionKind(redirectionKind)}
                        className="side-panel__channel-text-area"
                        maxLength={1500}
                    />
                </div>
            )}
        </div>
    );
};

type MockDataUnion =
    | typeof Rewards
    | typeof MessageTone
    | typeof Reminder
    | typeof ScheduleResolution
    | typeof CustomMessage
    | typeof Triage
    | typeof ResolutionDeadline
    | typeof ResolutionExpiryAction
    | typeof Help
    | typeof ResolveNow
    | typeof ActionButton
    | typeof AdditionalContext
    | typeof INSTRUCTIONS
    | typeof Escalation
    | typeof INCLUDE_MANAGER
    | typeof LaunchAction
    | typeof DelayEngagement
    | typeof Training
    | typeof LaunchActions;

export type MockDataType = Omit<CanvasType, 'value' | 'dropdownKey'> & { value: MockDataUnion };

export const MOCK_DATA: MockDataType[] = [
    {
        id: MESSAGE_TONE_ID,
        name: 'Message tone',
        isDraggable: true,
        disabled: false,
        value: MessageTone,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: SCHEDULE_RESOLUTION_ID,
        name: 'Schedule Resolution',
        isDraggable: true,
        disabled: false,
        value: ScheduleResolution,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: RESOLUTION_DEADLINE_ID,
        name: 'Resolution Deadline',
        isDraggable: true,
        disabled: false,
        value: ResolutionDeadline,
        category: EngagementPillsCategory.ESCALATE,
    },
    {
        id: REMINDER_ID,
        name: 'Reminder',
        isDraggable: true,
        disabled: false,
        value: Reminder,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: HELP_ID,
        name: 'Help',
        isDraggable: true,
        disabled: false,
        value: Help,
        category: EngagementPillsCategory.CONCLUDE,
    },
    {
        id: RESOLUTION_EXPIRY_ACTION_ID,
        name: 'Resolution Expiry Action',
        isDraggable: true,
        disabled: false,
        value: ResolutionExpiryAction,
        category: EngagementPillsCategory.ESCALATE,
    },
    {
        id: RESOLVE_NOW_ID,
        name: 'Resolve Now',
        isDraggable: true,
        disabled: false,
        value: ResolveNow,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: TRIAGE_ID,
        name: 'Triage (Yes/No)',
        isDraggable: true,
        disabled: false,
        value: Triage,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: CUSTOM_MESSAGE_ID,
        name: 'Custom Message',
        isDraggable: true,
        disabled: false,
        value: CustomMessage,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: ADDITIONAL_CONTEXT_ID,
        name: 'Additional Context',
        isDraggable: true,
        disabled: false,
        value: AdditionalContext,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: INCLUDE_MANAGER_ID,
        name: 'Include Manager',
        isDraggable: true,
        disabled: false,
        value: INCLUDE_MANAGER,
        category: EngagementPillsCategory.ESCALATE,
    },
    {
        id: INSTRUCTION_ID,
        name: 'Instructions',
        isDraggable: true,
        disabled: false,
        value: INSTRUCTIONS,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: ESCALATION_ID,
        name: 'Escalate to New Channel',
        isDraggable: true,
        disabled: false,
        value: Escalation,
        category: EngagementPillsCategory.ESCALATE,
    },
    {
        id: MARK_COMPLETE_ID,
        name: 'Mark Complete',
        isDraggable: true,
        disabled: false,
        value: MARK_COMPLETE,
        category: EngagementPillsCategory.CONCLUDE,
    },
    {
        id: REWARDS_ID,
        name: 'Rewards',
        isDraggable: true,
        disabled: false,
        value: Rewards,
        category: EngagementPillsCategory.CONCLUDE,
    },
    {
        id: LAUNCH_ACTIONS_ID,
        name: 'Launch Actions',
        isDraggable: true,
        disabled: false,
        value: LaunchActions,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: DELAY_ENGAGEMENT_ID,
        name: 'Delay Engagement',
        isDraggable: true,
        disabled: false,
        value: DelayEngagement,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: TRAINING_ID,
        name: 'Training',
        isDraggable: true,
        disabled: false,
        value: Training,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: REDIRECT_ENGAGEMENT_ID,
        name: 'Redirect Engagement',
        isDraggable: true,
        disabled: false,
        value: RedirectEngagement,
        category: EngagementPillsCategory.ESCALATE,
    },
    {
        id: ACTION_BUTTON_ID,
        name: 'Action Button',
        isDraggable: true,
        disabled: false,
        value: ActionButton,
        category: EngagementPillsCategory.DESIGN,
    },
    {
        id: MANAGER_ESCALATION_ID,
        name: 'Escalate to Manager',
        isDraggable: true,
        disabled: false,
        value: (props: EscalationProps) => <Escalation {...props} isManagerEscalation={true} />,
        category: EngagementPillsCategory.ESCALATE,
    },
];
