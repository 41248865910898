import { JamfSmartGroupDto } from '@ampsec/platform-client';
import { FaRegEdit } from '@react-icons/all-files/fa/FaRegEdit';
import { Typography } from 'Components/atoms';
import RoleDropdown from 'Components/pages/SettingsPage/RoleDropdown';
import { JAMF_SMART_GROUP_NAME, STATUS, STATUS_OPTIONS } from 'Core-utils/constants/constants';
import { opacitySmartGroupClassName } from 'Core-utils/helpers/helpers';
import { useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import './styles.scss';

export interface SmartGroupsConfigColumnsType {
    id: string;
    findingName: string;
    eventType?: string;
    severity: string;
    finding: string;
    description: string;
}
export enum SmartGroupStatus {
    ENABLED = 'ENABLED',
    DISABLED = 'DISABLED',
    ARCHIVED = 'ARCHIVED',
}
interface SmartGroupsConfigTableProps {
    handleEdit: (row: JamfSmartGroupDto) => void;
    handleUpdateStatus: (row: JamfSmartGroupDto, status: SmartGroupStatus) => void;
}

export const SMART_GROUPS_CONFIG_COLUMNS = (
    handleEdit: SmartGroupsConfigTableProps['handleEdit'],
    handleUpdateStatus: (row: JamfSmartGroupDto, status: SmartGroupStatus) => void,
): TableColumn<JamfSmartGroupDto>[] => [
    {
        id: 'groupName',
        name: <Typography variant="body2">{JAMF_SMART_GROUP_NAME}</Typography>,
        cell: (row: JamfSmartGroupDto) => (
            <div className={`findings-config__name ${opacitySmartGroupClassName(row)}`}>
                <Typography variant="body5">{row.groupName}</Typography>
            </div>
        ),
        style: {
            cursor: 'pointer',
        },
    },
    {
        id: 'status',
        name: <Typography variant="body2">{STATUS}</Typography>,
        cell: (row: JamfSmartGroupDto) => {
            const [value, setValue] = useState<SmartGroupStatus>(row.status as SmartGroupStatus);

            const handleDropdownChange = (eventKey: string | null, _e: any) => {
                setValue(eventKey as SmartGroupStatus);
                handleUpdateStatus(row, eventKey as SmartGroupStatus);
            };

            return (
                <div className={opacitySmartGroupClassName(row)}>
                    <RoleDropdown dropDownItems={STATUS_OPTIONS} value={value} onChange={handleDropdownChange} />
                </div>
            );
        },
        width: '20rem',
    },
    {
        id: 'edit',
        cell: (row: JamfSmartGroupDto) => (
            <div className={opacitySmartGroupClassName(row)}>
                <FaRegEdit
                    className="text-text-low-emphasis"
                    cursor={'pointer'}
                    onClick={() => handleEdit(row)}
                    size={16}
                />
            </div>
        ),
        width: '20rem',
    },
];
