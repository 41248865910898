import { Dropdown } from 'react-bootstrap';
import { RiArrowDownSLine } from '@react-icons/all-files/ri/RiArrowDownSLine';
import _ from 'lodash';
import { useState } from 'react';
import { CheckboxList } from 'Components/molecules';
import '../Dropdown/styles.scss';
import { useI18n } from 'Hooks/useI18n';

export type DynamicDropdownProps<T> = {
    options: T[];
    selectedOptionsClassName?: string;
    optionWithLinks?: {
        label: string;
        icon: React.ReactNode;
        url: string;
    };
    placeholder?: string;
    isStartCase?: boolean;
} & (
    | {
          variant: 'default';
          selectedOptions: T;
          onOptionSelected: (option: T) => void;
      }
    | {
          variant: 'multiSelect';
          selectedOptions: T[];
          onOptionSelected: (option: T[]) => void;
      }
);

function DynamicDropdown<T>({
    variant,
    options,
    selectedOptions,
    onOptionSelected,
    selectedOptionsClassName,
    placeholder,
    isStartCase = false,
}: DynamicDropdownProps<T>) {
    const { t } = useI18n();
    if (variant === 'multiSelect') {
        const [sortedOptions, setSortedOptions] = useState<T[]>(options.sort());

        const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => {
            const { id: option, checked: isChecked } = event.target;
            if (isChecked) {
                onOptionSelected([...selectedOptions, option as T]);
            } else {
                onOptionSelected(selectedOptions.filter((selectedOption) => selectedOption !== option));
            }
        };

        const handleDropdownToggle = (isOpen: boolean) => {
            if (!isOpen) {
                const sorted = _.sortBy(options, (option) => {
                    return selectedOptions.includes(option) ? 0 : 1;
                });
                setSortedOptions(sorted);
            }
        };
        const className = `body4 ${selectedOptionsClassName === 'custom' ? 'dropdown__text-wrap' : ''}`;

        return (
            <Dropdown onToggle={handleDropdownToggle}>
                <Dropdown.Toggle
                    variant="primary"
                    id="dropdown-basic"
                    className="d-flex justify-content-between align-items-center w-100 text-text-high-emphasis body4 border-text-medium-emphasis"
                >
                    {selectedOptions.length === 0 ? (
                        'Select'
                    ) : (
                        <span className={className} style={{ marginBottom: '0%' }}>
                            {selectedOptions.join(', ')}
                        </span>
                    )}
                    <RiArrowDownSLine className={'dropdown__dropdown-icon text-text-high-emphasis'} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 w-100 bg-body-color border-text-medium-emphasis dropdown-menu dropdown__custom-dropdown-menu">
                    <CheckboxList
                        options={sortedOptions}
                        selectedOptions={selectedOptions}
                        handleCheckbox={handleCheckbox}
                        header={false}
                    />
                </Dropdown.Menu>
            </Dropdown>
        );
    } else {
        const handleSelect = (eventKey: string | null) => {
            eventKey && onOptionSelected(eventKey as T);
        };
        const placeholderText = placeholder !== undefined ? `${placeholder}` : 'Select';
        const selectedOption = isStartCase ? t((selectedOptions as string) + '_description') : selectedOptions;
        return (
            <Dropdown onSelect={handleSelect} className="w-100">
                <Dropdown.Toggle
                    variant="primary"
                    className="d-flex justify-content-between align-items-center w-100 text-text-high-emphasis body4 border-text-medium-emphasis p-2"
                >
                    {selectedOptions ? (selectedOption as string) : placeholderText}
                    <RiArrowDownSLine className={'dropdown__dropdown-icon text-text-high-emphasis'} />
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-0 bg-body-color border-text-medium-emphasis custom-scrollbar dropdown-menu dropdown__custom-dropdown-menu">
                    {options.map((option: T) => {
                        const optionValue = isStartCase
                            ? t(((option as unknown) as string) + '_description')
                            : ((option as unknown) as string);
                        return (
                            <Dropdown.Item
                                key={option as string}
                                eventKey={option as string}
                                className="dropdown__menu-item d-flex align-items-center body2 text-uppercase"
                            >
                                {optionValue as string}
                            </Dropdown.Item>
                        );
                    })}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}

export default DynamicDropdown;
