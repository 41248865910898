import { ChangeEvent, ReactNode } from 'react';
import { DynamicTabContent } from './DynamicTabContent';
import { FormSettings } from 'Core-utils/types';
import { DynamicTab, IntegrationDataProps } from 'Components/organisms/Integrations/Integrations';

const convertToDynamicTabsToTabs = (
    tabs: DynamicTab[],
    vendor: IntegrationDataProps,
    selectedOptions: FormSettings,
    onSelectOption: (formId: string, fieldName: string, value: string | string[] | boolean) => void,
    onButtonClick: (
        formId: string,
        strategy?: string,
        type?: string,
    ) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => Promise<void>,
    hasPlaceholder?: boolean,
    customContent?: ReactNode,
) => {
    const handleOptionSelected = (formId: string, name: string, variant?: string) => (
        ev: ChangeEvent<HTMLInputElement>,
    ) => {
        onSelectOption &&
            onSelectOption(formId, name, variant && variant === 'list' ? ev.target.value.split(',') : ev.target.value);
    };
    const handleOptionChecked = (formId: string, name: string) => (ev: ChangeEvent<HTMLInputElement>) => {
        onSelectOption && onSelectOption(formId, name, ev.target.checked);
    };

    return tabs?.map((tab) => {
        const containsPlaceholder =
            (tab?.name?.toUpperCase() === 'INSTALLATION SETTINGS' ||
                tab?.name?.toUpperCase() === 'CREDENTIALS SETUP') &&
            hasPlaceholder
                ? true
                : false;
        return {
            title: tab?.name?.toUpperCase() ?? '',
            content: (
                <DynamicTabContent
                    selectedOptions={selectedOptions}
                    tab={tab}
                    vendor={vendor}
                    onOptionSelected={handleOptionSelected}
                    onOptionChecked={handleOptionChecked}
                    onButtonClick={onButtonClick}
                    hasPlaceholder={containsPlaceholder}
                    customContent={customContent}
                />
            ),
        };
    });
};

export default convertToDynamicTabsToTabs;
