import { Nav, Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { TabProps } from 'Core-utils/types/types';
import { Typography } from 'Components/atoms';
import './styles.scss';
import { useI18n } from 'Hooks/useI18n';

interface CustomTabProps {
    tabs: TabProps[];
}

const CustomTab = ({ tabs }: CustomTabProps) => {
    const { t } = useI18n();
    return (
        <div className="custom-tabs" data-testid="tabs">
            <Navbar className="tabs-container">
                {tabs.map(({ title, link }, index) => {
                    const typographyVariant = link ? 'body2' : 'subtitle2';
                    return (
                        <Nav.Link
                            as={NavLink}
                            className="nav___nav-link"
                            to={link}
                            key={index}
                            disabled={
                                // TODO: integarate with respective tabs
                                title === 'Templates'
                            }
                        >
                            <Typography variant={typographyVariant}>{t(title)}</Typography>
                        </Nav.Link>
                    );
                })}
            </Navbar>
        </div>
    );
};

export default CustomTab;
