import Select, { components, MultiValue } from 'react-select';
import './styles.scss';
import { Typography } from 'Components/atoms';

export interface OptionType {
    value: string;
    label: string;
    icon: string;
}

interface ChipMultiSelectProps {
    id: string;
    selected: OptionType[];
    onChange: (newValue: MultiValue<OptionType>) => void;
    placeholder: string;
    options: OptionType[];
}

const MultiValueLabel = (props: any) => {
    return (
        <components.MultiValueLabel {...props}>
            <img src={props.data.icon} alt="" className="bootstrap-select__multi-value-label" />
            {props.data.label}
        </components.MultiValueLabel>
    );
};

const CustomOption = (props: any) => {
    const { data, innerRef, innerProps } = props;
    return (
        <div ref={innerRef} {...innerProps} className="bootstrap-select__custom-option d-flex align-items-center">
            <img src={data.icon} alt="" className="bootstrap-select__multi-value-label" />
            <Typography variant={'caption1'} className="mb-0">
                {data.label}
            </Typography>
        </div>
    );
};

const ChipMultiSelect = ({ id, placeholder, selected, options, onChange }: ChipMultiSelectProps) => (
    <div className="container w-100 ps-2">
        <Select
            id={id}
            options={options}
            className="bootstrap-select"
            classNamePrefix="select"
            placeholder={placeholder}
            onChange={onChange}
            value={selected}
            isMulti
            components={{ MultiValueLabel, Option: CustomOption }}
        />
    </div>
);

export default ChipMultiSelect;
